import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';
// import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";
import logo from './assets/logo.png'

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            {/* <a href=".">LOGO</a> */}
            {/* <img src={logo} alt="alternatetext" style={{ width: '220px', height: '220px' }}/> */}
          </div>
          <div className="social">
            <a href="https://www.facebook.com/profile.php?id=100093300714532" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://instagram.com/hornbillconnect2?igshid=MzRlODBiNWFlZA==" title="Instagram" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
            {/* <a href="https://github.com/arkn98/coming-soon" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a> */}
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
          <img src={logo} alt="alternatetext" style={{ width: '300px', height: '300px' }}/>
            <h1>Get ready for the change.</h1>
            <p>Celebrate from afar with style. Unleash joy across miles with surprise gifts, personalized events, and elite photography.</p>
              <p>Be there virtually through live streaming, igniting their special day with grace.</p>
          </div>
          <a href="mailto:hornbillconnect@gmail.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          {/* <span>made by <a className="underlined" href="https://github.com/" target="_blank" rel="noopener noreferrer">arkn98</a> using <a className="underlined" href="https://reactjs.org/" title="ReactJS" target="_blank" rel="noopener noreferrer">React</a> | <a className="underlined" href="https://github.com/arkn98/coming-soon" title="GitHub repo" target="_blank" rel="noopener noreferrer">GitHub</a></span> */}
        </div>
      </div>
    );
  }
}

export default App;